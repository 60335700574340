<template>
  <div class="home-container">

    <!-- <van-pull-refresh v-model="isLoading" @refresh="onRefresh"> -->
    <div class="index-top" style="position: fixed;">
      <div class="top-title">
        <span>{{ city }}</span>
        <van-icon name="location-o" color="#666666" size="0.5rem" />
        <van-search v-model="value" show-action shape="round" placeholder="请输入搜索内容" @search="onSearch">
          <template #action>
            <div style="font-size: 0.33rem;border-radius: 1rem;padding: 0 0.5rem;background-color: #0097FF;color: #FFFFFF;" @clear="onSearch('')"> 搜索</div>
          </template>
        </van-search>
        <!-- <van-icon name="exchange" color="#666666" size="0.5rem" />
         -->
        <!-- <img style="width: 0.5rem;" src="../../assets/img/fenxiang.png" /> -->

      </div>

    </div>

    <div v-show="show" style="    background-color: #FFFFFF;margin: 0 auto;padding: 0.5rem;
    padding-top: 1.8rem;font-size:0.37rem
     ;">

      <div style=" color: #666666;
    font-size: 0.4rem;    ">
        热门搜索
      </div>
      <div style="margin-top: 0.35rem;    display: flex;
    flex-wrap: wrap;">

        <span @click.stop="onSearch(i.word)" style="border-radius: 0.2rem; >
    background: #F8F8F8;
    box-sizing: border-box;
    padding: 0.2rem;
    margin-top: 0.3rem;
    width: 23%;
    text-align: center;
    margin-right: 0.18rem;" v-for="i in channels" :key="i">{{ i.word }}</span>
      </div>
    </div>
    <div v-show="!show" style="width: 95%;margin: 0 auto;padding-top: 1.8rem;">
      <div class="list-title">
        <span> 互联网权益券</span>
        <span style='    font-size: 0.32rem;
    color: #666666;
    font-weight: 500;'>任选其一</span>
      </div>

      <div class="index-list" style="margin-bottom: 1rem;">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onloads">
          <div class="list-content" @click="selecthref('scdetail1', { detailid: i.id })" v-for="i in goodslist">
            <div class="list-img">
              <img :src="i.picture" style="width: 2rem;">
            </div>
            <div class="list-info">
              <span>{{ i.name }}</span>
              <span style="margin-bottom: 0.1rem;margin-top: 0.15rem;    background: none;
            text-align: left;
            font-size: 0.35rem;
            font-weight: 700;"><a v-if="i.receiveType == '0'">免费领取</a></span>
              <span style="    color: #999999;
            font-size: 0.35rem; text-decoration: line-through;">
                {{ i.price }}
              </span>
            </div>
          </div>
        </van-list>
      </div>
      <!-- <div class="index-bottom" @click="addgoodlist">
          <van-loading v-show="loading == '1'" size="24px">加载中...</van-loading>
          <span v-show="loading == '0'">加载更多</span>
          <span v-show="loading == '3'" style="color: #6A737D;">已无更多</span>

        </div> -->

    </div>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
import {
  getCouponList,
  internetList,
  getSelectBusiSearchHotWordList
} from '@/api/user'
import {
  mapState
} from 'vuex'
import {
  getItem,
  setItem
} from '@/utils/storage'
import {
  loadbmap
} from '@/api/loadbmap'
export default {
  name: 'home',

  props: {},
  data() {
    return {
      isLoading: false,
      active: 0, //被激活的列表
      show: false,
      loading: false,
      finished: false,
      from: 0,
      value: '',
      city: '',
      areaId: '', //城市id
      goodslist: [], //商品列表
      longitude: '', //经度
      couponType: '',
      searchWord: '',
      latitude: '', //纬度
      channels: [], //频道列表
      goodslist: [

      ],
      isChannelEditShow: false //频道编辑层
    }
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {},
  created() {
    console.log(this.$route);
    // this.loadChannels()
  },
  mounted() {
    this.city = '滨州市'
    // this.loadChannels()
  },
  methods: {


    onloads() {

      const _this = this
      const city = ''
      this.show = getItem('show')
      // setItem('show',false)
      if (getItem('latitude') != '' && getItem('latitude') != null) {
        _this.latitude = getItem('latitude')
        _this.longitude = getItem('longitude')
        if (getItem('localtion') !== null) {
          _this.city = getItem('localtion').name
          _this.areaId = getItem('localtion').id
        }
        // _this.areaId = getItem('localtion').id
      }

      if (_this.latitude == '') {
        Dialog.alert({
          message: '您所在的地市尚未开通 PLUS生活会员业务，请跳转到其他地市。',
        }).then(() => {
          this.selecthref('selectcity')
          // on close
        });
        // this.loadChannels()
      } else {

        _this.CouponList()
      }
    },
    selecthref(href, data = '') {

      this.$router.push({
        name: href,
        params: data
      })

    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
      }, 1000);
      this.from = 1;
      this.goodslist = []
      this.searchWord = ''
      this.show = false
      this.getlocation()
    },
    getlocation() {
      const _this = this
      let baidu = new _this.BMap.Geolocation()
      var city = ''
      baidu.getCurrentPosition((r) => {
        // _this.latitude = r.latitude
        _this.latitude = '37.387738'
        // _this.longitude = r.longitude
        _this.longitude = '117.976538'
        setItem('latitude', _this.latitude)
        setItem('longitude', _this.latitude)
        if (getItem('localtion') !== null) {
          // _this.city = getItem('localtion').name
          _this.city = '滨州市'
          // _this.areaId = getItem('localtion').id
        } else {
          // _this.city = r.address.city
          _this.city = '滨州市'

        }
        _this.CouponList()
      })
    },

    async CouponList() {
      const _this = this
      this.from += 1
      let param = {

        from: this.from,
        size: 5,
      }
      const {
        data
      } = await internetList(param)

      // this.goodslist = data.data
      if (JSON.stringify(this.goodslist) === '[]') {
        this.goodslist = data.data
      } else {
        data.data.forEach((i, k) => {
          this.goodslist.push(i)

        })
      }
      if (JSON.stringify(data.data) === '[]' || data.data.length < 5) {
        this.loading = true
        this.finished = true
      } else {
        this.loading = false
      }
      // this.finished=true
    },
    async loadChannels() {
      const {
        data
      } = await getSelectBusiSearchHotWordList()
      this.channels = data.data
      console.log(this.channels)
    },

    // onUpdateActive(index) {
    //   this.active = index
    // }
    async onSearch(value) {
      console.log(value)

      this.value = value
      this.from = 0
      this.searchWord = value
      this.goodslist = []
      this.loading = true
      this.finished = false
      await this.CouponList()
      this.show = !this.show
    },


    // onUpdateActive(index) {
    //   this.active = index
    // }
  }
}
</script>

<style scoped lang="less">
.my-swipe .van-swipe-item {
  width: 100%;
  height: 3rem;
  // color: #fff;
  // font-size: 20px;
  // line-height: 150px;
  // text-align: center;
  // background-color: #39a9ed;

  img {
    width: 100%;
    height: 100%;
  }
}

.van-search__content {
  // background-color: #FFFFFF;
}

.van-search--show-action {
  padding: 0;
  margin: 0 0.2rem;
  border-radius: 10rem !important;
}

.van-search__action {
  padding: 0;
}

.van-search {
  width: 72%;
  background-color: #f7f8fa;
}

.home-container {
  .index-top {
    padding: 0.2rem 0;
    background-color: #ffffff;
    margin-bottom: 0.5rem;
    width: 100%;
    // height: 2.5rem;
  }

  .top-title {
    display: flex;
    /* align-content: center; */
    align-items: center;
    width: 95%;
    margin: 0 auto;
    padding-top: 0.2rem;
    font-size: 0.35rem;
    justify-content: center;

    span {
      padding: 0 0.09rem;
    }
  }

  .index-nav {
    font-size: 0.3rem;
    font-weight: 700;

    img {
      height: 0.8rem;
      width: 0.8rem;
    }

    span {
      padding-top: 0.15rem;
      color: #333333;
    }
  }

  .index-banner {
    width: 88%;
    margin: 0 auto;
    background: #fff;
    margin-top: 0.3rem;
    font-size: 0.4rem;
    color: #333333;
    font-weight: 700;
    padding: 0.3rem;
    padding-bottom: 0;
    position: relative;

    div {
      margin-bottom: 0.2rem;
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  .list-title {
    background-image: url(../../assets/img/navs.png);
    font-size: 0.4rem;
    width: 100%;
    height: 1.2rem;
    background-size: 100% 100%;
    display: flex;
    box-sizing: border-box;
    justify-content: space-between;
    padding-right: 0.5rem;

    span {
      display: block;
      padding-top: 0.3rem;
      font-weight: 700;
      padding-left: 0.3rem;
    }
  }

  .list-content {
    border-radius: 0.2rem;
    margin-bottom: 0.3rem;
    display: flex;
    background: url(../../assets/img2/goodslistbg.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0.3rem 0.5rem;

    img {
      display: block;
      // width: 1.8rem;
      height: 2rem;
    }
  }

  .list-info {
    padding-left: 0.5rem;

    span {
      display: block;
      font-size: 0.3rem;

      &:nth-child(1) {
        font-size: 0.4rem;
        color: #333333;
        font-family: SourceHanSansCN-Regular;
      }

      &:nth-child(2) {
        padding: 0.1rem 0;
        font-size: 0.3rem;
        background: #ffe9e9;
        border-radius: 0.25rem;
        text-align: center;
        margin: 0.28rem;
        width: 2.5rem;
        color: #0869ab;
        margin-left: 0;
      }

      &:nth-child(3) {
        font-size: 0.3rem;
        color: #999999;
      }
    }
  }

  .index-bottom {
    /* margin: 0rem 0px; */
    width: 90%;
    background: #fff;
    margin: 0 auto;
    font-size: 0.4rem;
    /* margin-top: 0.1rem; */
    margin-bottom: 2rem;
    padding: 0.5rem;
    text-align: center;
    border-radius: 0.2rem;
  }

  /deep/ .van-nav-bar__title {
    max-width: none;
  }

  .search-btn {
    width: 277px;
    height: 32px;
    background: #5babfb;
    border: none;

    .van-icon {
      font-size: 16px;
    }

    .van-button__text {
      font-size: 14px;
    }
  }
}
</style>
